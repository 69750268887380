body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f5f5f5;
  color: #333333;
}

.app-container {
  padding: 2rem;
  text-align: center;
  max-width: 100%;
}

.title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #4CAF50; /* Green color for the main title */
  margin-bottom: 1.5rem;
}

.query-form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1.5rem;
  justify-content: center;
}

.clickable-row:hover {
  background-color: #e0f7fa; /* Light teal for hover effect */
  transition: background-color 0.3s ease;
}

.compute-button {
  font-size: 1rem;
  font-weight: 600;
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background-color: #4CAF50;
  color: #ffffff;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.compute-button:hover {
  background-color: #43a047; /* Darker green on hover */
}

.content-grid {
  margin-top: 1.5rem;
}

.tourney-list, .ranking-table {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 100%; /* Ensure full width within the grid layout */
}

.section-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333333;
  margin-bottom: 1rem;
}

.parameters {
  font-size: 0.9rem;
  color: #777777;
  margin-bottom: 1rem;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th, table td {
  padding: 0.75rem;
  text-align: left;
  font-size: 0.95rem;
}

table th {
  background-color: #4CAF50;
  color: #ffffff;
  font-weight: 600;
  border-radius: 8px 8px 0 0;
}

table td {
  border-bottom: 1px solid #e0e0e0;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

@media (max-width: 600px) {
  .content-flex-container {
    flex-direction: column;
  }
}

.content-flex-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
}


